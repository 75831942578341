































































































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { IAutomation } from '@/interfaces/models/IAutomation';
import { Validate } from 'vuelidate-property-decorators';
import { required, requiredIf } from "vuelidate/lib/validators";
import { AutomationTypeEnum } from '@/interfaces/enums/automation-type.enum';
import * as actionTypes from '@/store/action-types';
import { NotificationType } from '@/interfaces/models/INotification';

/**
 * Creating/editing automation modal
 */
@Component({
  name: 'CreateAutomationModal',
})
export default class CreateAutomationModal extends Vue {
  @Prop({type: Object, default: null}) automation!: IAutomation | null;
  @Prop({type: Boolean, default: false}) showDialog!: boolean;

  @Validate({required}) name: string = '';
  @Validate({required}) automationType: AutomationTypeEnum | null = AutomationTypeEnum.KEYWORD;
  @Validate({required: requiredIf((vm) => vm.showKeywordInput)}) keyword: string = '';
  allowOtherKeywords: boolean = true;
  description: string = '';

  automationTypes: Array<{ value: AutomationTypeEnum, text: string }> = [
    {
      value: AutomationTypeEnum.KEYWORD,
      text : 'Keyword'
    },
  ];
  loading: boolean = false;

  get title(): string {
    return this.automation?.id ? 'Edit automation' : 'New automation';
  }

  get buttonText(): string {
    return this.automation?.id ? 'Update' : 'Create';
  }

  get showKeywordInput(): boolean {
    return this.automationType === AutomationTypeEnum.KEYWORD;
  }

  get disableTypeChanging(): boolean {
    return !!this.automation;
  }

  get isNameEditable(): boolean {
    if (!this.automation?.id) {
      return true;
    }

    return this.automation.nameEditable;
  }

  @Watch('showDialog')
  watchAutomation(): void {
    if (this.automation) {
      this.name = this.automation.name;
      this.keyword = this.automation.keyword;
      this.automationType = this.automation.type;
      this.description = this.automation.description;
      this.allowOtherKeywords = this.automation.allowOtherKeywords;
    }
  }

  /**
   * Emitting close event and resetting modal values
   */
  @Emit('close-dialog')
  closeDialog(): void {
    this.name = '';
    this.automationType = null;
    this.description = '';
    this.$v.$reset();
  }

  /**
   * Handle submitting modal based on automation action type
   */
  submitModal(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    if (this.automation?.id) {
      this.editAutomation();
    } else {
      this.createAutomation();
    }
  }

  /**
   * Editing automation
   */
  async editAutomation(): Promise<void> {
    try {
      this.loading = true;
      await this.$store.dispatch(actionTypes.UPDATE_AUTOMATION, {
        id                : this.automation!.id,
        name              : this.name,
        type              : this.automationType,
        description       : this.getDescription(),
        keyword           : this.keyword,
        allowOtherKeywords: this.allowOtherKeywords,
      });
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.loading = false;
      this.closeDialog();
    }
  }

  /**
   * Creating automation
   */
  async createAutomation(): Promise<void> {
    try {
      this.loading = true;
      const newAutomation: IAutomation = await this.$store.dispatch(actionTypes.CREATE_AUTOMATION, {
        name              : this.name,
        type              : this.automationType,
        description       : this.getDescription(),
        keyword           : this.keyword,
        allowOtherKeywords: this.allowOtherKeywords
      });
      this.closeDialog();
      await this.$router.push(`/settings/automations/${newAutomation.id}`);
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.loading = false;
    }
  }

  getDescription(): string | null {
    if (this.automation) {
      return this.automation.description && !this.description.trim().length ? '' : this.description;
    }
    return this.description.trim().length ? this.description : null;
  }
}
