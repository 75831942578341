



































































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Validate } from 'vuelidate-property-decorators';
import { maxValue, minValue, required, integer } from 'vuelidate/lib/validators';

@Component({
  name: 'AutomationStepDelay'
})
export default class AutomationStepDelay extends Vue {
  @Prop({type: Boolean, required: true}) showDialog!: boolean;
  @Prop({type: Number, required: true}) triggerDelayDays!: number;
  @Prop({type: Number, required: true}) triggerDelayHours!: number;
  @Prop({type: Number, required: true}) triggerDelayMinutes!: number;

  @Validate({required, integer, minValue: minValue(0)})
  days: number = 0;
  @Validate({required, integer, minValue: minValue(0), maxValue: maxValue(24)})
  hours: number = 0;
  @Validate({required, integer, minValue: minValue(0), maxValue: maxValue(59)})
  minutes: number = 0;

  @Watch('showDialog')
  observeShowDialog(): void {
    this.days = this.triggerDelayDays;
    this.hours = this.triggerDelayHours;
    this.minutes = this.triggerDelayMinutes;
  }

  /**
   * Validate form to prevent emitting wrong values
   */
  changeDelay(): void {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    this.emiChangeDelay();
  }

  /**
   * Emitting new delay values
   */
  @Emit('change-delay')
  emiChangeDelay(): {triggerDelayDays: number, triggerDelayHours: number, triggerDelayMinutes: number} {
    return {
      triggerDelayDays: this.days,
      triggerDelayHours: this.hours,
      triggerDelayMinutes: this.minutes,
    };
  }

  /**
   * Emitting closing dialog
   */
  @Emit('close-dialog')
  closeDialog(): void {
  }
}
