































































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import EndWebhookInput from '@/components/admin-settings/automation-steps-settings/EndWebhookInput.vue';
import EndWebhookHeaders from '@/components/admin-settings/automation-steps-settings/EndWebhookHeaders.vue';
import * as actionTypes from '@/store/action-types';
import { NotificationType } from '@/interfaces/models/INotification';
import { DEFAULT_FIELDS } from '@/common/constants';
import { IEndWebhook } from '@/interfaces/models/IEndWebhook';
import { IEndWebhookMappingField } from '@/interfaces/models/IEndWebhookMappingField';

@Component({
  name      : 'EndWebhookMappingDialog',
  components: {EndWebhookInput, EndWebhookHeaders}
})
export default class EndWebhookMappingDialog extends Vue {
  @Prop({type: Boolean, required: true}) showDialog!: boolean;
  @Prop({type: Object, required: true}) endWebhook!: IEndWebhook;
  @Prop({type: Boolean}) updateURL!: boolean;

  activeTab: number = 0;
  isLoading: boolean = false;

  fieldsToMap: Array<IEndWebhookMappingField> = [{inputField: "", contactField: ""}];
  headersToMap: Array<[any, any]> = [];

  @Watch('updateURL')
  observeUpdateURL(needsToBeUpdated: any): void {
    if (needsToBeUpdated) {
      this.saveMapping();
    }
  }

  @Emit('url-updated')
  urlWasUpdated(): void {
  }

  @Emit('close-dialog')
  closeDialog(afterSave: boolean): void {
    if(!afterSave) {
      this.fieldsToMap = this.endWebhook.fieldMapping ? [...this.endWebhook.fieldMapping] : [{inputField: "", contactField: ""}];
      this.headersToMap = this.endWebhook.headerMapping ? [...Object.entries(this.endWebhook.headerMapping)] : [];
    }
  }
  mounted(): void {
    if (this.endWebhook.id) {
      this.fieldsToMap = [...this.endWebhook.fieldMapping];
      this.headersToMap = this.endWebhook.headerMapping ? [...Object.entries(this.endWebhook.headerMapping)] : [];
    }
  }

  async saveMapping(): Promise<void> {
    const inputs: EndWebhookInput[] = this.$refs.input as EndWebhookInput[];
    const headers: EndWebhookHeaders[] = this.$refs.header as EndWebhookHeaders[];
    if (inputs?.some(({isValid}) => !isValid()) || headers?.some(({isValid}) => !isValid())) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: "Form is invalid",
        type: NotificationType.ERROR
      });
      return;
    }

    try {
      this.isLoading = true;
      const fieldMapping = inputs
        ?.map(({mappingField, newClientFieldName}) => this.getMappedFields(mappingField, newClientFieldName));
      const headerMapping = headers ? Object.fromEntries(headers?.map(header => [header.newHeaderName, header.newHeaderValue])) : {};
      if (this.endWebhook.id) {
        await this.$store.dispatch(actionTypes.UPDATE_END_WEBHOOK, {
        id: this.endWebhook.id,
        url: this.endWebhook.url,
        fieldMapping,
        headerMapping
      });
      this.urlWasUpdated();
      } else {
        await this.$store.dispatch(actionTypes.CREATE_END_WEBHOOK, {
        automationId: this.$route.params.id,
        url: this.endWebhook.url,
        fieldMapping,
        headerMapping
      });
      }

      this.closeDialog(true);
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }

  getMappedFields(mappingFieldId: number | undefined, inputField: string): object {
    const defaultField = DEFAULT_FIELDS.find(({id}) => id === mappingFieldId)?.value;

    return defaultField ? {
      inputField,
      contactField: defaultField
    } : {
      inputField,
      customFieldId: mappingFieldId
    };
  }

  async addProperty(): Promise<void> {
    const inputs: EndWebhookInput[] = this.$refs.input as EndWebhookInput[];
    const mappedFields: IEndWebhookMappingField[] = inputs
        ?.map(({mappingField, newClientFieldName}) => this.getMappedFields(mappingField, newClientFieldName)) as IEndWebhookMappingField[]; // check if previous fields aren't empty

    if (mappedFields.some(field => Object.values(field).some(v => !v))) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: "Please fill empty fields before adding another one",
        type: NotificationType.ERROR
      });
      return;
    }
    this.fieldsToMap = [...mappedFields, {inputField: "", contactField: ""}]; // update Array with fields we've already added;
	}

  async addHeader(): Promise<void> {
    const headers: EndWebhookHeaders[] = this.$refs.header as EndWebhookHeaders[];
    if (headers?.some(header => !header.newHeaderName || !header.newHeaderValue)) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: "Please fill empty headers before adding another one",
        type: NotificationType.ERROR
      });
      return;
    }
    const mappedHeaders: Array<[string, any]> = headers?.map(header => [header.newHeaderName, header.newHeaderValue]);
    if (mappedHeaders) {
      this.headersToMap = [...mappedHeaders, ["",""]]; // update Array with headers we've already added;
    } else {
      this.headersToMap.push(["",""]); // update Array with headers we've already added;
    }
	}

  removeField(fieldNameToRemove: any): void {
    const _ = require('lodash');
    const filteredFields = this.fieldsToMap.filter(field => field.inputField !== fieldNameToRemove);
    if (_.isEqual(this.fieldsToMap, filteredFields)) {
      this.fieldsToMap = filteredFields.slice(0,-1);
    } else {
      this.fieldsToMap = filteredFields;
    }

    // this.fieldsToMap = this.fieldsToMap.filter(field => field.inputField !== fieldNameToRemove);
  }

  removeHeader(headerNameToRemove: any): void {
    const _ = require('lodash');
    const filteredHeaders = this.headersToMap.filter(header => header[0] !== headerNameToRemove);
    if (_.isEqual(this.headersToMap, filteredHeaders)) {
      this.headersToMap = filteredHeaders.slice(0,-1);
    } else {
      this.headersToMap = filteredHeaders;
    }

    // this.headersToMap = this.headersToMap.filter(header => header[0] !== headerNameToRemove);
  }
}
