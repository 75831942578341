

































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import * as actionTypes from "@/store/action-types";
import { NotificationType } from "@/interfaces/models/INotification";
import { Getter } from "vuex-class";
import { IAutomation } from "@/interfaces/models/IAutomation";
import { IAutomationStep } from "@/interfaces/models/IAutomationStep";
import { IEndWebhook } from "@/interfaces/models/IEndWebhook";
import { AutomationTypeEnum } from "@/interfaces/enums/automation-type.enum";
import CreateAutomationModal from "@/components/admin-settings/automations-settings/CreateAutomationModal.vue";
import AutomationStepItem from "@/components/admin-settings/automation-steps-settings/AutomationStepItem.vue";
import EndWebhookItem from "@/components/admin-settings/automation-steps-settings/EndWebhookItem.vue";
import WebhookMappingDialog from "@/components/admin-settings/automation-steps-settings/WebhookMappingDialog.vue";

@Component({
  name: "AutomationSteps",
  components: {
    WebhookMappingDialog,
    AutomationStepItem,
    CreateAutomationModal,
    EndWebhookItem
  }
})
export default class AutomationSteps extends Vue {
  @Getter activeAutomation!: IAutomation;
  @Getter automationSteps!: IAutomationStep[];
  @Getter endWebhooks!: IEndWebhook[];

  showEditAutomationDialog: boolean = false;
  placeholderSteps: number[] = [];
  openedStepIdx: number = -1;
  showWebhookMappingDialog: boolean = false;
  automationEndWebhooks: any[] = [];
  showEndWebhookMappingDialog: boolean = false;

  get activeAutomationType(): string {
    return this.activeAutomation.type === AutomationTypeEnum.KEYWORD
      ? "Keyword"
      : "Initial contact";
  }

  get isKeywordAutomation(): boolean {
    return this.activeAutomation.type === AutomationTypeEnum.KEYWORD;
  }

  get webhookURL(): string {
    return this.activeAutomation.startAutomationWebhookUrl;
  }

  @Watch("endWebhooks")
  observeEndWebhooks(): void {
    this.automationEndWebhooks = [...this.endWebhooks];
  }

  // when navigating between not children routes (e.g: /automation/1 -> /inbox/17)
  @Watch("$route.params.automationId")
  async observeParamsId(): Promise<void> {
    await this.fetchAllData();
  }

  // scrollIntoView only when automationSteps are fetched, so the document.getElementById(...) won't ne null
  @Watch("automationSteps")
  observeAutomationSteps(): void {
    if (this.$route.query.stepId) {
      this.$nextTick(() => {
        document.getElementById(`automation-step-${this.$route.query.stepId}`)?.scrollIntoView();
      });
    }
  }

  async fetchAllData(): Promise<void> {
    await Promise.all([
      this.fetchActiveAutomation(),
      this.fetchAutomationSteps(),
      this.fetchAutomationEndWebhooks()
    ]);

    this.automationEndWebhooks = [...this.endWebhooks];

    await Promise.all([
      this.$store.dispatch(actionTypes.FETCH_TAGS),
      this.$store.dispatch(actionTypes.FETCH_CUSTOM_FIELDS),
      this.$store.dispatch(actionTypes.FETCH_DEPARTMENTS)
    ]);
  }
  async mounted(): Promise<void> {
    await this.fetchAllData();
  }

  beforeDestroy(): void {
    this.$store.dispatch(actionTypes.RESET_ACTIVE_AUTOMATION);
    this.$store.dispatch(actionTypes.RESET_AUTOMATION_STEPS);
  }

  openStep(idx: number): void {
    this.openedStepIdx = idx;
  }

  closeStep(): void {
    this.openedStepIdx = -1;
  }

  addPlaceholderStep(): void {
    this.placeholderSteps.push(Math.floor(Math.random() * 1000));
  }

  async addEndWebhook(): Promise<void> {
    if (this.automationEndWebhooks.some(eWebhook => eWebhook.url === "")) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: "You have an empty end-webhook already created",
        type: NotificationType.ERROR
      });
      return;
    }
    this.automationEndWebhooks.push({ url: "" });
  }

  removeEmptyEndWebhook(): void {
    this.automationEndWebhooks.pop();
  }

  openWebhookMappingDialog(): void {
    this.showWebhookMappingDialog = true;
  }

  removeStep(index: number, step: number = -1): void {
    this.placeholderSteps.splice(index, 1);
    if (step === this.openedStepIdx) {
      this.openedStepIdx = -1;
    }
  }

  /**
   * Loading active automation
   */
  async fetchActiveAutomation(): Promise<void> {
    try {
      const automationId = Number(this.$route.params.automationId);
      if (Number.isNaN(automationId)) {
        return;
      }

      await this.$store.dispatch(actionTypes.FETCH_AUTOMATION_BY_ID, automationId);
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }

  /**
   * Loading automation steps
   */
  async fetchAutomationSteps(): Promise<void> {
    try {
      const automationId = Number(this.$route.params.automationId);
      if (Number.isNaN(automationId)) {
        return;
      }

      await this.$store.dispatch(actionTypes.FETCH_AUTOMATION_STEPS, automationId);

      if (!this.automationSteps.length) {
        this.addPlaceholderStep();
      }
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }

  /**
   * Loading automation end webhooks
   */
  async fetchAutomationEndWebhooks(): Promise<void> {
    try {
      const automationId = Number(this.$route.params.automationId);
      if (Number.isNaN(automationId)) {
        return;
      }
      await this.$store.dispatch(actionTypes.FETCH_END_WEBHOOKS, automationId);
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }

  /**
   * Opening dialog for editing active automation
   */
  editActiveAutomation(): void {
    this.showEditAutomationDialog = true;
  }

  copyWebhookURL(): void {
    navigator.clipboard.writeText(this.webhookURL);
  }
}
