




































	import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
	import { IEndWebhook } from "@/interfaces/models/IEndWebhook";
	import EndWebhookMappingDialog from "@/components/admin-settings/automation-steps-settings/EndWebhookMappingDialog.vue";
  import { ConfirmModalButtonColorEnum, IConfirmModal } from '@/interfaces/confirm-modal.interface';
  import ConfirmModal from '@/components/admin-settings/ConfirmModal.vue';
  import { DEFAULT_END_WEBHOOK_CONFIG, DEFAULT_CONFIRM_MODAL_CONFIG } from '@/common/constants';
  import { NotificationType } from '@/interfaces/models/INotification';
	import { Validate } from "vuelidate-property-decorators";
	import { required } from "vuelidate/lib/validators";
	import * as actionTypes from "@/store/action-types";
	import { Getter } from "vuex-class";

	@Component({
		name: "EndWebhookItem",
		components: {EndWebhookMappingDialog, ConfirmModal},
	})
	export default class EndWebhookItem extends Vue {
    @Prop({type: Object, required: false}) webhook!: IEndWebhook;

    endWebhook: IEndWebhook = DEFAULT_END_WEBHOOK_CONFIG;
    showEndWebhookMappingDialog: Boolean = false;
    isRemoving: boolean = false;
    showConfirmModal: boolean = false;
    confirmModalConfig: IConfirmModal = DEFAULT_CONFIRM_MODAL_CONFIG;
    updateWebhookUrl: boolean = false;

    created(): void {
      this.endWebhook = {...this.webhook}
    }

    @Emit('remove-empty-webhook')
    removeEmptyEndWebhook(): void {
    }

    removeWebhook(event: Event): void {
      if (!this.endWebhook.id) {
        this.removeEmptyEndWebhook();
      } else {
      event.stopPropagation();
      this.confirmModalConfig = {
      title        : 'Remove end webhook',
      label        : `You are about to remove end webhook. This change can not be reversed. Are you sure you want to do this?`,
      buttonText   : 'Remove',
      buttonColor  : ConfirmModalButtonColorEnum.DELETE,
      confirmAction: async () => {
        if (this.endWebhook) {
          try {
            this.isRemoving = true;
            await this.$store.dispatch(actionTypes.REMOVE_END_WEBHOOK, this.endWebhook.id);
          } catch (e) {
            await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
              text: e.message,
              type: NotificationType.ERROR
            });
          } finally {
            this.isRemoving = false;
          }
        }
      },
    };
    this.showConfirmModal = true;
    }
    }

    async showEndWebhookMappingDialogFunction() : Promise<void> {
      if (this.endWebhook.url) {
        this.showEndWebhookMappingDialog = true
      } else {
        await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
          text: "End Webhook URL needed",
          type: NotificationType.ERROR
        });
      }
    }

    updateURL(): void {
      if (this.webhook.url !== this.endWebhook.url && this.webhook.id) {
        this.updateWebhookUrl = true;
      }
    }
  }
