








































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IAutomationStep } from "@/interfaces/models/IAutomationStep";
import { IResponseMapping } from "@/interfaces/models/IResponseMapping";
import AutomationStepDelay from "@/components/admin-settings/automation-steps-settings/AutomationStepDelay.vue";
import { Validate } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import * as actionTypes from "@/store/action-types";
import { NotificationType } from "@/interfaces/models/INotification";
import { Getter } from "vuex-class";
import { IAutomation } from "@/interfaces/models/IAutomation";
import { IAutomationStepQuestionType } from "@/interfaces/models/IAutomationStepQuestionType";
import { AUTOMATION_STEP_QUESTION_TYPES, DEFAULT_CONFIRM_MODAL_CONFIG } from "@/common/constants";
import AutomationStepStatement from "@/components/admin-settings/automation-steps-settings/AutomationStepStatement.vue";
import { AutomationQuestionTypeEnum } from "@/interfaces/enums/automation-question-type.enum";
import imageLoadingHelper from "@/common/utils/image-loading-helper";
import GiphyPopup from "@/components/inbox/GiphyPopup.vue";
import { ConfirmModalButtonColorEnum, IConfirmModal } from "@/interfaces/confirm-modal.interface";
import ConfirmModal from "@/components/admin-settings/ConfirmModal.vue";
import { Helpers } from "@/common/utils/helpers.js";
import EmojiPicker from "@/components/emoji-picker/EmojiPicker.vue";

@Component({
  name: "AutomationStepItem",
  components: {
    ConfirmModal,
    GiphyPopup,
    AutomationStepStatement,
    AutomationStepDelay,
    EmojiPicker
  }
})
export default class AutomationStepItem extends Vue {
  @Getter activeAutomation!: IAutomation;

  @Prop({ type: Number }) stepIndex!: number;
  @Prop({ type: Object }) automationStep!: IAutomationStep;
  @Prop({ type: Boolean, default: false }) isOpen!: boolean;

  @Validate({ required }) questionType: AutomationQuestionTypeEnum | null = null;
  @Validate({
    required: (_: any, self: AutomationStepItem): boolean => {
      return !!self.mediaFile || !!self.value;
    }
  })
  value: string = "";
  responseMapping: IResponseMapping[] = [];

  triggerDelayDays: number = 0;
  triggerDelayHours: number = 0;
  triggerDelayMinutes: number = 0;

  showDelayDialog: boolean = false;
  loading: boolean = false;
  countOfStatements: number[] = [-1];
  mediaFile: string = "";
  imageName: string = "";
  isRemoving: boolean = false;
  showConfirmModal: boolean = false;
  confirmModalConfig: IConfirmModal = DEFAULT_CONFIRM_MODAL_CONFIG;

  @Watch("automationStep", { immediate: true, deep: true })
  observeAutomationStep(): void {
    if (this.automationStep) {
      this.questionType = this.automationStep.questionType;
      this.value = this.automationStep.question;
      this.triggerDelayMinutes = this.automationStep.triggerDelayMinutes;
      this.triggerDelayHours = this.automationStep.triggerDelayHours;
      this.triggerDelayDays = this.automationStep.triggerDelayDays;
      this.countOfStatements = this.automationStep.responseMapping?.map((_, index) => index) || [
        -1
      ];
      this.mediaFile = this.automationStep.questionImage ?? "";
      this.imageName = "Image";

      if (
        this.automationStep.questionType === AutomationQuestionTypeEnum.NOTIFICATION &&
        this.automationStep.responseMapping?.length === 0
      ) {
        this.addResponseMapping();
      }
    }
  }

  get isHasDelay(): boolean {
    return !!this.triggerDelayDays || !!this.triggerDelayHours || !!this.triggerDelayMinutes;
  }

  get delayString(): string {
    const days =
      this.triggerDelayDays === 0
        ? "Same day"
        : this.triggerDelayDays === 1
        ? `${this.triggerDelayDays} day later`
        : `${this.triggerDelayDays} days later`;
    const hours =
      this.triggerDelayHours === 1
        ? `${this.triggerDelayHours} hour`
        : `${this.triggerDelayHours} hours`;
    const minutes =
      this.triggerDelayHours === 1
        ? `${this.triggerDelayMinutes} minute`
        : `${this.triggerDelayMinutes} minutes`;

    return `${days} -  ${hours} : ${minutes}`;
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  get isNotificationQuestionType(): boolean {
    return this.questionType === AutomationQuestionTypeEnum.NOTIFICATION;
  }

  get isSingleAnswerType(): boolean {
    return this.questionType === AutomationQuestionTypeEnum.SINGLE_ANSWER;
  }

  get questionTypes(): IAutomationStepQuestionType[] {
    return AUTOMATION_STEP_QUESTION_TYPES;
  }

  /**
   * Opening delay dialog
   */
  openDelayDialog(event: Event): void {
    event.stopPropagation();
    this.showDelayDialog = true;
  }

  /**
   * Closing delay dialog
   */
  closeDelayDialog(): void {
    this.showDelayDialog = false;
  }

  loadImage(): void {
    (this.$refs.imageInput as HTMLInputElement).click();
  }

  /**
   * Removing automation step
   * @param event
   */
  removeAutomationStep(event: Event): void {
    event.stopPropagation();
    this.confirmModalConfig = {
      title: "Remove automation step",
      label: `You are about to remove automation step. This change can not be reversed. Are you sure you want to do this?`,
      buttonText: "Remove",
      buttonColor: ConfirmModalButtonColorEnum.DELETE,
      confirmAction: async () => {
        if (this.automationStep) {
          try {
            this.isRemoving = true;
            await this.$store.dispatch(actionTypes.REMOVE_AUTOMATION_STEP, this.automationStep.id);
          } catch (e) {
            await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
              text: e.message,
              type: NotificationType.ERROR
            });
          } finally {
            this.isRemoving = false;
          }
        }
        this.$emit("remove-step");
      }
    };
    this.showConfirmModal = true;
  }

  async handleImageSelection(event: any): Promise<void> {
    try {
      this.imageName = event.target.files[0].name.split(".")[0];
      this.mediaFile = await imageLoadingHelper(event);
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }

  async handleGIFSelection({ url, title }: { url: string; title: string }): Promise<void> {
    this.mediaFile = url.split("?cid")[0];
    this.imageName = title.split("GIF")[0].trim();
  }

  async findURL(): Promise<void> {
    this.loading = true;
    const res: any = await this.changeUrlFromText();
    this.loading = false;
    let replacedText = this.value;

    if (res) {
      res.convertedUrls.forEach((shortUrl: any, index: number) => {
        const url1 = res.matchUrls[index];
        replacedText = replacedText.replace(url1, shortUrl);
      });
      this.value = replacedText;
    }
  }

  async changeUrlFromText(): Promise<void> {
    return await Helpers.urlify(this.value);
  }

  /**
   * Appending new emoji to textarea value
   */
  appendEmoji(emoji: string): void {
    const lastCursorPosition = this.$el.querySelector("textarea")?.selectionStart || 0;
    this.value =
      this.value.substr(0, lastCursorPosition) + emoji + this.value.substr(lastCursorPosition);

    // outside click to close emoji picker
    document.body.click();
  }

  /**
   * Saving new delay value
   * @param event - new delay values
   */
  saveDelay(event: {
    triggerDelayDays: number;
    triggerDelayHours: number;
    triggerDelayMinutes: number;
  }): void {
    this.triggerDelayDays = event.triggerDelayDays;
    this.triggerDelayHours = event.triggerDelayHours;
    this.triggerDelayMinutes = event.triggerDelayMinutes;

    this.closeDelayDialog();
  }

  /**
   * Resting delay values
   */
  removeDelay(event: Event): void {
    event.stopPropagation();
    this.triggerDelayDays = this.triggerDelayHours = this.triggerDelayMinutes = 0;
  }

  /**
   * Opening automation item
   */
  openAutomation(): void {
    if (this.isOpen) {
      this.$emit("close-item", this.stepIndex);
    } else {
      this.$emit("open-item", this.stepIndex);
    }
  }

  changeQuestionType(): void {
    if (this.questionType !== AutomationQuestionTypeEnum.NOTIFICATION) {
      this.countOfStatements = [-1];
    }
  }

  /**
   * Adding new response mapping
   * Random number is needed for correct tracking of component
   */
  addResponseMapping(): void {
    this.countOfStatements.push(Math.floor(Math.random() * 1000));
  }

  /**
   * Removing response mapping
   * @param index - index for response mapping
   */
  removeResponseMapping(index: number): void {
    this.countOfStatements.splice(index, 1);
  }

  /**
   * Delay to allow all fields be updated
   */
  fakeDelay(): Promise<void> {
    return new Promise<void>(resolve => {
      setTimeout(resolve, 100);
    });
  }

  /**
   * Validating fields and creating action for crating new automation step
   */
  async saveStep(): Promise<void> {
    const statements: AutomationStepStatement[] = (this.$refs.statements ??
      []) as AutomationStepStatement[];
    statements.forEach(statement => statement.validate());
    const isStatementsValid = statements.every(statement => statement.isFormValid());
    this.$v.$touch();
    if (this.$v.$invalid || !isStatementsValid) {
      return;
    }

    try {
      this.loading = true;
      await this.fakeDelay();

      const filteredStatements = statements
        .map(statement => statement.createResponseObject())
        .filter(statement => statement.actions?.length);
      if (this.automationStep) {
        await this.$store.dispatch(actionTypes.UPDATE_AUTOMATION_STEP, {
          automationStepId: this.automationStep.id,
          updateData: {
            automationId: this.activeAutomation.id,
            questionType: this.questionType,
            question: this.value,
            questionImage: this.mediaFile,
            responseMapping: filteredStatements,
            triggerDelayDays: this.triggerDelayDays,
            triggerDelayHours: this.triggerDelayHours,
            triggerDelayMinutes: this.triggerDelayMinutes
          }
        });
      } else {
        await this.$store.dispatch(actionTypes.CREATE_AUTOMATION_STEP, {
          automationId: this.activeAutomation.id,
          questionType: this.questionType,
          question: this.value || null,
          questionImage: this.mediaFile,
          responseMapping: filteredStatements,
          triggerDelayDays: this.triggerDelayDays,
          triggerDelayHours: this.triggerDelayHours,
          triggerDelayMinutes: this.triggerDelayMinutes
        });
      }
      this.openAutomation();
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.loading = false;
      this.$emit("save-step");
    }
  }
}
