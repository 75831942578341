export interface IConfirmModal {
  title: string;
  label: string;
  buttonColor: ConfirmModalButtonColorEnum;
  buttonText: string;
  confirmAction: () => void;
}

export enum ConfirmModalButtonColorEnum {
  DELETE = '#FE4D97',
  CONFIRM = '#4D7CFE'
}
