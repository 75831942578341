import { ConfirmModalButtonColorEnum, IConfirmModal } from '@/interfaces/confirm-modal.interface';
import { IAutomationStepQuestionType } from '@/interfaces/models/IAutomationStepQuestionType';
import { AutomationQuestionTypeEnum } from '@/interfaces/enums/automation-question-type.enum';
import { IEndWebhook } from "@/interfaces/models/IEndWebhook";

export const DEFAULT_CONFIRM_MODAL_CONFIG: IConfirmModal = {
  title        : '',
  label        : '',
  buttonText   : '',
  buttonColor  : ConfirmModalButtonColorEnum.CONFIRM,
  confirmAction: () => {
  },
};

export const AUTOMATION_STEP_QUESTION_TYPES: IAutomationStepQuestionType[] = [
  {
    text: 'Notification',
    value: AutomationQuestionTypeEnum.NOTIFICATION,
  },
  {
    text: 'Single answer',
    value: AutomationQuestionTypeEnum.SINGLE_ANSWER,
  },
  {
    text: 'Multiple answers',
    value: AutomationQuestionTypeEnum.MULTIPLE_ANSWERS,
  },
];

export const DEFAULT_FIELDS = [
  {id: -1, name: 'First name', value: 'firstName'},
  {id: -2, name: 'Last name', value: 'lastName'},
  {id: -3, name: 'Email', value: 'email'},
  {id: -4, name: 'Company Name', value: 'companyName'},
  {id: -5, name: 'Title', value: 'title'},
  {id: -6, name: 'Phone number', value: 'phoneNumber'},
];

export const DEFAULT_END_WEBHOOK_CONFIG: IEndWebhook = {
  id             : -1,
  url            : '',
  fieldMapping   : {},
  headerMapping  : {}
};
