



















































































































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { AutomationQuestionTypeEnum } from "@/interfaces/enums/automation-question-type.enum";
import { Getter } from "vuex-class";
import { ICustomField } from "@/interfaces/models/ICustomField";
import { IDepartment } from "@/interfaces/models/IDepartment";
import { IActionInterface } from "@/interfaces/models/IActionInterface";
import { ResponseMappingActionsEnum } from "@/interfaces/enums/response-mapping-actions.enum";
import ITag from "@/interfaces/models/tag.interface";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { IResponseMapping } from "@/interfaces/models/IResponseMapping";
import GiphyPopup from "@/components/inbox/GiphyPopup.vue";
import imageLoadingHelper from "@/common/utils/image-loading-helper";
import * as actionTypes from "@/store/action-types";
import { NotificationType } from "@/interfaces/models/INotification";
import { Helpers } from "@/common/utils/helpers.js";
import EmojiPicker from "@/components/emoji-picker/EmojiPicker.vue";

@Component({
  name: "AutomationStepStatement",
  components: { GiphyPopup, EmojiPicker }
})
export default class AutomationStepStatement extends Vue {
  @Getter customFields!: ICustomField[];
  @Getter departments!: IDepartment[];
  @Getter tags!: ITag[];

  @Prop({ required: true }) statement!: IResponseMapping | null;
  @Prop({ type: String, required: true }) questionType!: AutomationQuestionTypeEnum;
  @Prop({ type: Number, required: true }) index!: number;

  actions: IActionInterface[] = [];
  value: string = "";
  withMessage: boolean = false;
  mediaFile: string = "";
  imageName: string = "";

  @Validations()
  validations() {
    return {
      ifText: this.withoutUserResponseField ? {} : { required },
      value: this.withMessage ? { required } : {},
      actions: {
        $each: {
          type: this.questionType === AutomationQuestionTypeEnum.NOTIFICATION ? {} : { required },
          value:
            this.questionType === AutomationQuestionTypeEnum.NOTIFICATION
              ? {
                  required: (...data: any[]) => {
                    if (data.length === 0) {
                      return false;
                    }
                    if (data[1]?.type === "subscribe" || data[1]?.type === "unsubscribe") {
                      return true;
                    }
                    return data[1]?.type ? !!data[1]?.value : true;
                  }
                }
              : {
                  required: (...data: any[]) => {
                    if (data[1]?.type === "subscribe" || data[1]?.type === "unsubscribe") {
                      return true;
                    }
                    return data[1]?.type ? !!data[1]?.value : true;
                  }
                },
          fieldValue: {}
        }
      }
    };
  }

  defaultFields: any[] = [
    { id: -1, name: "First name", value: "firstName" },
    { id: -2, name: "Last name", value: "lastName" },
    { id: -3, name: "Email", value: "email" },
    { id: -4, name: "Company Name", value: "companyName" },
    { id: -5, name: "Title", value: "title" }
  ];

  defaultMappingActionTypes = [
    {
      value: ResponseMappingActionsEnum.TAG,
      text: "Tag"
    },
    {
      value: ResponseMappingActionsEnum.UNTAG,
      text: "Untag"
    },
    {
      value: ResponseMappingActionsEnum.SAVE_TO_FIELD,
      text: "Save to field"
    },
    {
      value: ResponseMappingActionsEnum.ASSIGN_DEPARTMENT,
      text: "Assign department"
    },
    {
      value: ResponseMappingActionsEnum.SUBSCRIBE,
      text: "Subscribe"
    },
    {
      value: ResponseMappingActionsEnum.UNSUBSCRIBE,
      text: "Unsubscribe"
    }
  ];
  responseMappingActionTypes = [...this.defaultMappingActionTypes];

  ifText: string = "";

  @Watch("withMessage")
  observeWithMessage(): void {
    if (!this.withMessage) {
      this.mediaFile = this.imageName = "";
    }
  }

  @Watch("questionType", { immediate: true })
  observeQuestionType(): void {
    if (this.questionType === AutomationQuestionTypeEnum.SINGLE_ANSWER) {
      this.actions = [
        {
          value: "",
          type: ResponseMappingActionsEnum.SAVE_TO_FIELD
        }
      ];
    } else if (this.questionType === AutomationQuestionTypeEnum.NOTIFICATION) {
      this.actions = [
        {
          value: "",
          type: null
        }
      ];
    } else {
      this.actions = [
        {
          value: "",
          type: null
        }
      ];
    }

    if (this.questionType === AutomationQuestionTypeEnum.NOTIFICATION) {
      this.responseMappingActionTypes = this.defaultMappingActionTypes.filter(
        action => action.value !== ResponseMappingActionsEnum.SAVE_TO_FIELD
      );
    } else {
      this.responseMappingActionTypes = [...this.defaultMappingActionTypes];
    }
  }

  @Watch("statement", { immediate: true, deep: true })
  observeStatement(): void {
    if (this.statement) {
      this.ifText = this.statement.answer;
      this.withMessage = !!this.statement.withMessage?.length;
      this.value = this.statement.withMessage;
      this.mediaFile = this.statement.withMessageImage ?? "";
      this.imageName = "Image";
      this.actions = this.statement.actions.map(action => {
        const value = action.contactField
          ? this.defaultFields.find(({ value }) => value === action.contactField)?.id
          : action.tagId || action.customFieldId || action.departmentId;
        const fieldValue = action.value;
        return {
          type: action.action,
          value,
          fieldValue
        };
      });
    }
  }

  /**
   * Emitting removing response mapping
   */
  @Emit("remove-response-mapping")
  removeResponseMapping(): number {
    return this.index;
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  /**
   * Check if current question type is single answer
   */
  get isSingleAnswerQuestion(): boolean {
    return this.questionType !== AutomationQuestionTypeEnum.MULTIPLE_ANSWERS;
  }

  get withoutUserResponseField(): boolean {
    return this.questionType !== AutomationQuestionTypeEnum.MULTIPLE_ANSWERS;
  }

  /**
   * Appending new emoji to textarea value
   */
  appendEmoji(emoji: string): void {
    if (!this.withMessage) {
      return;
    }

    const lastCursorPosition = this.$el.querySelector("textarea")?.selectionStart || 0;
    this.value =
      this.value.substr(0, lastCursorPosition) + emoji + this.value.substr(lastCursorPosition);

    // outside click to close emoji picker
    document.body.click();
  }

  loadImage(): void {
    (this.$refs.imageInput as HTMLInputElement).click();
  }

  async handleImageSelection(event: any): Promise<void> {
    try {
      this.imageName = event.target.files[0].name.split(".")[0];
      this.mediaFile = await imageLoadingHelper(event);
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }

  async handleGIFSelection({ url, title }: { url: string; title: string }): Promise<void> {
    this.mediaFile = url.split("?cid")[0];
    this.imageName = title.split("GIF")[0].trim();
  }

  async findURL(): Promise<void> {
    this.isLoading(true);
    const res: any = await this.changeUrlFromText();
    this.isLoading(false);
    let replacedText = this.value;

    if (res) {
      res.convertedUrls.forEach((shortUrl: any, index: number) => {
        const url1 = res.matchUrls[index];
        replacedText = replacedText.replace(url1, shortUrl);
      });
      this.value = replacedText;
    }
  }

  async changeUrlFromText(): Promise<void> {
    return await Helpers.urlify(this.value);
  }

  isLoading(load: boolean): void {
    this.$emit("isLoading", load);
  }
  /**
   * Add new assignment selects row
   */
  addField(): void {
    this.actions.push({
      value: "",
      type: this.isSingleAnswerQuestion ? ResponseMappingActionsEnum.SAVE_TO_FIELD : null
    });
  }

  /**
   * Remove assignment selects row by index
   * @param index - index of assignment row
   */
  removeAssignment(index: number): void {
    this.actions.splice(index, 1);
  }

  /**
   * Get values for assignment dropdown based on assignment type
   * @param type - type of assignment
   */
  getValuesForAssignment(
    type: ResponseMappingActionsEnum
  ): ITag[] | IDepartment[] | ICustomField[] {
    switch (type) {
      case ResponseMappingActionsEnum.ASSIGN_DEPARTMENT:
        return this.departments;
      case ResponseMappingActionsEnum.SAVE_TO_FIELD:
        return [...this.defaultFields, ...this.customFields];
      case ResponseMappingActionsEnum.UNTAG:
      case ResponseMappingActionsEnum.TAG:
        return this.tags;
      default:
        return [];
    }
  }

  /**
   * Validating fields inside component
   */
  validate(): void {
    this.$v.$touch();
  }

  /**
   * Checking is all fields inside component valid
   */
  isFormValid(): boolean {
    return !this.$v.$invalid;
  }

  createResponseObject(): any {
    const filteredActions = this.actions.filter(({ type }) => !!type);

    if (!filteredActions.length) {
      return {};
    }

    return {
      answer: this.ifText,
      withMessage: this.withMessage ? this.value : "",
      withMessageImage: this.mediaFile,
      actions: filteredActions.map((action: IActionInterface) => {
        const obj: any = {};
        obj.action = action.type || undefined;

        switch (action.type) {
          case ResponseMappingActionsEnum.SAVE_TO_FIELD:
            obj.value = action.fieldValue;
            if (action.value < 0) {
              obj.contactField = this.defaultFields.find(({ id }) => id === action.value).value;
            } else {
              obj.customFieldId = action.value;
            }
            break;
          case ResponseMappingActionsEnum.ASSIGN_DEPARTMENT:
            obj.departmentId = action.value;
            break;
          case ResponseMappingActionsEnum.TAG:
          case ResponseMappingActionsEnum.UNTAG:
            obj.tagId = action.value;
            break;
        }

        return obj;
      })
    };
  }
}
