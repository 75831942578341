




























import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ICustomField } from '@/interfaces/models/ICustomField';
import { IDefaultField } from '@/interfaces/models/IDefaultField';
import { Validate } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import { DEFAULT_FIELDS } from '@/common/constants';

@Component({
  name: 'EndWebhookHeaders'
})
export default class EndWebhookHeaders extends Vue {
  @Prop({type: String, required: true}) headerName!: string;
  @Prop({required: true}) headerValue!: any;

  @Validate({required})
  newHeaderName: string = "";
  @Validate({required})
  newHeaderValue: string = "";

  anyDirty: boolean = false;

  isValid(): boolean {
    this.anyDirty = true;
    return !this.$v.$invalid;
  }

  mounted(): void {
    this.setHeadersField();
  }

  @Watch('headerName')
  observeHeaderName(): void {
    this.setHeadersField();
  }

  setHeadersField(): void {
    this.newHeaderName = this.headerName; // to be able change them
    this.newHeaderValue = this.headerValue; // to be able change them
  }

  @Emit("remove-header")
  removeHeader(): string {
    return this.newHeaderName;
  }
}
