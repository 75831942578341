



























































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import Pusher from 'pusher-js';
import { ENV } from '@/common/utils/env';
import { IWebhookMapping } from '@/interfaces/models/IWebhookMapping';
import WebhookInput from '@/components/admin-settings/automation-steps-settings/WebhookInput.vue';
import * as actionTypes from '@/store/action-types';
import { NotificationType } from '@/interfaces/models/INotification';
import { DEFAULT_FIELDS } from '@/common/constants';
import { IAutomation } from '@/interfaces/models/IAutomation';

@Component({
  name      : 'WebhookMappingDialog',
  components: {WebhookInput}
})
export default class WebhookMappingDialog extends Vue {
  @Prop({type: Boolean, required: true}) showDialog!: boolean;
  @Prop({type: Object, required: true}) automation!: IAutomation;
  pusher: Pusher = new Pusher(ENV.PUSHER_APP_KEY, {
    cluster: 'us2'
  });

  activeTab: number = 0;
  isLoading: boolean = false;
  request: object | null = null;
  startAutomationWebhookMappingCopy: Array<object> = [];

  @Emit('close-dialog')
  closeDialog(): void {
  }

  mounted(): void {
    this.startAutomationWebhookMappingCopy = this.automation.startAutomationWebhookMapping;

    const channel = this.pusher.subscribe('textodog-automation'); // we are getting request from all automations, not from only one
    channel.bind('startAutomationWebhookTriggered', (data: IWebhookMapping) => {
      this.request = JSON.parse(data.jsonData);
    });
  }

  beforeDestroy(): void {
    this.pusher.unsubscribe('textodog-automation');
  }

  requestKeys(): string[] {
    if (!this.request) {
      return [];
    }

    return Object.keys(this.request);
  }

  async saveMapping(): Promise<void> {
    const inputs: WebhookInput[] = this.$refs.input as WebhookInput[];

    if (!this.request && !this.automation.startAutomationWebhookMapping || inputs?.some(({isValid}) => !isValid())) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: "Form is invalid",
        type: NotificationType.ERROR
      });
      return;
    }

    try {
      this.isLoading = true;
      const startAutomationWebhookMapping = inputs
        ?.map(({mappingField, fieldName}) => this.getMappedFields(mappingField, fieldName));

      await this.$store.dispatch(actionTypes.UPDATE_AUTOMATION, {
        id: this.automation.id,
        startAutomationWebhookMapping
      });
      this.closeDialog();
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }

  async resetMapping(): Promise<void> {
    this.startAutomationWebhookMappingCopy = [];
    this.request = null;
    this.activeTab = 0;
  }

  getMappedFields(mappingFieldId: number | undefined, inputField: string): object {
    const defaultField = DEFAULT_FIELDS.find(({id}) => id === mappingFieldId)?.value;

    return defaultField ? {
      inputField,
      contactField: defaultField
    } : {
      inputField,
      customFieldId: mappingFieldId
    };
  }
}
