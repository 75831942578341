










import {Vue, Component, Prop} from "vue-property-decorator";
import SettingItemInterface from "@/interfaces/setting-item.interface";

@Component
export default class SettingItem extends Vue {
  @Prop({type: Object, required: true}) item!: SettingItemInterface;
}
