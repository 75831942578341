
































import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ICustomField } from '@/interfaces/models/ICustomField';
import { IDefaultField } from '@/interfaces/models/IDefaultField';
import { Validate } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import { DEFAULT_FIELDS } from '@/common/constants';

@Component({
  name: 'EndWebhookInput'
})
export default class EndWebhookInput extends Vue {
  @Getter customFields!: ICustomField[];
  @Prop({type: String, required: true}) clientFieldName!: string;
  @Prop({required: true}) textodogFieldName!: any;

  @Validate({required})
  mappingField: number | undefined = 0;
  @Validate({required})
  newClientFieldName: string = "";

  defaultFields: any[] = DEFAULT_FIELDS;
  anyDirty: boolean = false;

  get fields(): IDefaultField[] {
    return [
      ...this.defaultFields,
      ...this.customFields
    ];
  }

  isValid(): boolean {
    this.anyDirty = true;
    return !this.$v.$invalid;
  }

  mounted(): void {
    this.setMappingField();
  }

  @Watch('clientFieldName')
  observeClientFieldName(): void {
    this.setMappingField();
  }

  setMappingField(): void {
    this.newClientFieldName = this.clientFieldName; // to be able change them
    this.mappingField = this.fields.find((field) => {
      if (field.value && typeof this.textodogFieldName === "string") {
        return field.value === this.textodogFieldName;
      } else {
        return field.id === this.textodogFieldName;
      }
    })?.id;
  }

  @Emit("remove-field")
  removeField(): string {
    return this.newClientFieldName;
  }
}
