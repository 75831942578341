import SettingItemInterface from "../../interfaces/setting-item.interface";

export const ADMIN_MENU_ITEMS: SettingItemInterface[] = [
  // {
  //   iconName: 'widget-menu',
  //   title: 'Url Widgets',
  //   subtitle: 'Setup widget and default answers',
  //   link: '/settings/widgets'
  // },
  {
    iconName: 'company',
    title: 'Company Settings',
    subtitle: 'Company details and general settings',
    link: '/settings/company',
    unlimitedOnly: false,
  },
  {
    iconName: 'organization',
    title: 'My Team',
    subtitle: 'Set up your team and their roles',
    link: '/settings/organization',
    unlimitedOnly: false,
  },
  {
    iconName: 'rocket',
    title: 'Automations',
    subtitle: 'SMS marketing and more',
    link: '/settings/automations',
    unlimitedOnly: false,
  },
  {
    iconName: 'reply-menu',
    title: 'Saved Replies',
    subtitle: 'Custom templates for quick replies',
    link: '/settings/saved-replies',
    unlimitedOnly: false,
  },
  {
    iconName: 'card',
    title: 'Custom Fields / Tags',
    subtitle: 'Create fields related to your business',
    link: '/settings/custom-fields',
    unlimitedOnly: false,
  },
  // {
  //   iconName: 'deals',
  //   title: 'Textodeals/Textobaines',
  //   subtitle: 'List your offers on our deals site',
  //   link: '/settings/textodeals-textobaines',
  //   unlimitedOnly: false,
  // },
  {
    iconName: 'billing-menu',
    title: 'Billing Information',
    subtitle: 'Pricing options and change your plan',
    link: '/settings/billing',
    unlimitedOnly: true,
  },
  {
    iconName: 'integration-menu',
    title: 'Integrations',
    subtitle: 'Connect to over 3000 apps',
    link: '/settings/integrations',
    unlimitedOnly: false,
  },
];

export const SUPER_ADMIN_MENU_ITEMS: SettingItemInterface[] = [
  {
    iconName: 'company',
    title: 'Companies',
    subtitle: 'All companies management',
    link: '/super-admins/companies',
    unlimitedOnly: false,
  },
  // {
  //   iconName: 'deals',
  //   title: 'Textodeals',
  //   subtitle: 'All textodeals management',
  //   link: '/super-admins/textodeals',
  //   unlimitedOnly: false,
  // },
];
